<template>
    <div class="home-index-page">
        <section
            class="home-index-page__slider"
            :class="{ 'no-auth': runtimeConfig.public.variant !== 'megadescuentos' }"
        >
            <h1 class="container my-3 text-2xl font-semibold text-gray-800">
                {{ $lang.views.homepage.best_week_offers }}
            </h1>
            <WidgetSlider
                :class="
                    runtimeConfig.public.variant === 'megadescuentos'
                        ? 'h-[353px] lg:h-[401px]'
                        : 'h-[251px] lg:h-[295px]'
                "
            >
                <WidgetSliderItem v-for="(item, key) in pageData?.page.data.card_featured_offers" :key="key">
                    <MiscLazy mode="featured-full" :ignore-lazy="key < 3">
                        <DCardVertical
                            :info="item"
                            :lazy-image="key > 1"
                            :no-auth="runtimeConfig.public.variant !== 'megadescuentos'"
                        />
                    </MiscLazy>
                </WidgetSliderItem>
            </WidgetSlider>
        </section>

        <div class="home-index-page__content">
            <main class="main-content">
                <section class="main-content__discount-list">
                    <h2>
                        {{ $lang.views.homepage.best_offers_and_coupons_from }}
                        {{ currentDates.month }}
                        {{ currentDates.year }}
                    </h2>
                    <!-- v-if="$config.public.variant === 'megadescuentos'" -->
                    <MiscTabs :info="tabsInfo" class="mb-4" />
                    <ul class="flex flex-col">
                        <template v-if="pageData.page.cta_cards && pageData.page.cta_cards.length">
                            <li
                                v-for="(cta, key) in pageData.page.cta_cards"
                                :key="'cta-' + key"
                                :style="{ order: cta.position }"
                                class="mb-3"
                            >
                                <CTAHandler :cta="cta" />
                            </li>
                        </template>
                        <li
                            v-for="(item, key) in tabData"
                            :key="key"
                            :style="{ order: key + 1 }"
                            class="mb-3"
                        >
                            <DelayHydration>
                                <MiscLazy mode="global" :ignore-lazy="key < 2" no-placeholder>
                                    <DCardAdmin v-if="item.is_admin" :info="item" :show-brand-logo="true" />
                                    <DCardMain v-else :info="item" in-stores-route="true" />
                                </MiscLazy>
                            </DelayHydration>
                        </li>
                    </ul>
                    <ButtonLoadMore
                        v-if="fetchButton.show"
                        :loading="fetchButton.loading"
                        :handler="loadMoreHome"
                        class="mt-4"
                    />
                </section>
                <section v-if="featuredStores && featuredCategories">
                    <div class="main-content__subtitle">
                        <h2>{{ $lang.views.homepage.popular_stores }}</h2>
                        <NuxtLink no-prefetch :to="`/${$lang.routes.brands}`">{{
                            $lang.views.homepage.see_more
                        }}</NuxtLink>
                    </div>
                    <MiscStoreGallery :stores="featuredStores" />
                    <div class="main-content__subtitle">
                        <h2>{{ $lang.views.homepage.top_categories }}</h2>
                        <NuxtLink no-prefetch :to="`/${$lang.routes.categories}`">{{
                            $lang.views.homepage.see_more
                        }}</NuxtLink>
                    </div>
                    <MiscCategoriesChips :categories="featuredCategories" />
                </section>
                <section v-if="pageData.page.content" class="main-content__server-content">
                    <div v-html="pageData.page.content"></div>
                </section>
            </main>
            <aside class="sidebar-content">
                <SideBanners :banner-pos="[1, 2]" />
                <SideCoupons :discounts="featuredCoupons" />
            </aside>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'
import type { Helpers } from '~/types/helpers'
import type { Models } from '~/types/models'

import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const runtimeConfig = useRuntimeConfig()
const { currentDate } = useDateFunctions()

const currentDates = currentDate()
const { $lang } = useNuxtApp()
const Route = useRoute()
const AuthStore = useAuthStore()
const RootStore = useRootStore()

const tabMeta = ref(null) as Ref<null | Helpers.Meta>

const tabData = ref([]) as Ref<Models.Discount[]>

const params = reactive({
    page: 1,
})

const fetchButton = reactive({
    loading: false,
    show: false,
})

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const headers = buildHeaders(AuthStore.SessionToken)

const fetchHome = () =>
    $fetch<Api.Responses.Pages.Home>(endpoints.pages.home.path, {
        method: 'GET',
        headers,
        baseURL,
        params,
    })
const fetchHomeCommented = () =>
    $fetch<Api.Responses.Pages.HomeCommented>(endpoints.pages.home.commented, {
        method: 'GET',
        headers,
        baseURL,
        params,
    })
const fetchHomeLatest = () =>
    $fetch<Api.Responses.Pages.HomeLatest>(endpoints.pages.home.latest, {
        method: 'GET',
        headers,
        baseURL,
        params,
    })

const config = useRuntimeConfig()

const { data: homepageData, error } = await useAsyncData('homepage-data', async () => {
    try {
        const pageData = await fetchHome()

        let commented = null as null | Api.Responses.Pages.HomeCommented

        let latest = null as null | Api.Responses.Pages.HomeLatest

        if (Route.path === '/' + $lang.routes.commented) commented = await fetchHomeCommented()

        if (Route.path === '/' + $lang.routes.news) latest = await fetchHomeLatest()

        return {
            pageData,
            commented,
            latest,
        }
    } catch (error) {
        throw createError({
            statusCode: 500,
            message: 'Algo salió mal',
        })
    }
})

if (!homepageData.value) throw new Error('Homepage data not found')

params.page = 2

const { pageData, commented, latest } = homepageData.value

if (Route.path === '/' + $lang.routes.news && latest) {
    tabData.value = [...latest.data.new_discounts.data]
    tabMeta.value = {
        ...latest.data.new_discounts.meta,
    }
} else if (Route.path === '/' && pageData) {
    tabData.value = [...pageData.page.data.popular_discounts.data]
    tabMeta.value = {
        ...pageData.page.data.popular_discounts.meta,
    }
} else if (Route.path === '/' + $lang.routes.commented && commented) {
    tabData.value = [...commented.data.most_comment_discounts.data]
    tabMeta.value = {
        ...commented.data.most_comment_discounts.meta,
    }
}

fetchButton.show =
    (tabMeta.value?.total &&
        tabMeta.value?.current_page &&
        tabMeta.value?.last_page &&
        tabMeta.value?.total > 0 &&
        tabMeta.value?.current_page < tabMeta.value?.last_page) ||
    false

const featuredCoupons = computed(() => {
    return RootStore.layoutData?.data.sidebar.coupons || []
})
const featuredStores = computed(() => {
    return RootStore.layoutData?.data.sidebar.stores || []
})
const featuredCategories = computed(() => {
    return RootStore.layoutData?.data.sidebar.categories || []
})

const tabsInfo = {
    show: Route.path === '/' ? 0 : Route.path === '/' + $lang.routes.news ? 1 : 2,
    tabs: [
        { to: '/', title: $lang.views.homepage.popular },
        {
            to: `/${$lang.routes.news}`,
            title: $lang.views.homepage.news,
        },
        ...(runtimeConfig.public.variant === 'megadescuentos'
            ? [
                  {
                      to: `/${$lang.routes.commented}`,
                      title: $lang.views.homepage.commented,
                  },
              ]
            : []),
    ],
}

const loadMoreHome = async () => {
    fetchButton.loading = true

    if (Route.path === '/' + $lang.routes.news) {
        const result = await fetchHomeLatest()

        if (result?.feedback === 'data_success') {
            tabData.value = [...tabData.value, ...result.data.new_discounts.data]

            const { current_page, last_page } = result.data.new_discounts.meta

            if (!last_page || current_page === last_page) {
                fetchButton.show = false
            } else {
                params.page = current_page + 1
            }
        }
    } else if (Route.path === '/') {
        const result = await fetchHome()

        if (result?.feedback === 'data_success') {
            tabData.value = [...tabData.value, ...result.page.data.popular_discounts.data]

            const { current_page, last_page } = result.page.data.popular_discounts.meta

            if (!last_page || current_page === last_page) {
                fetchButton.show = false
            } else {
                params.page = current_page + 1
            }
        }
    } else if (Route.path === '/' + $lang.routes.commented) {
        const result = await fetchHomeCommented()

        if (result?.feedback === 'data_success') {
            tabData.value = [...tabData.value, ...result.data.most_comment_discounts.data]

            const { current_page, last_page } = result.data.most_comment_discounts.meta

            if (!last_page || current_page === last_page) {
                fetchButton.show = false
            } else {
                params.page = current_page + 1
            }
        }
    }

    fetchButton.loading = false
}

const {
    public: { origin, siteName },
} = useRuntimeConfig()

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [pageData.page.title || '', pageData.page.description || ''],
)

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
    ...(Route.path !== '/' ? { robots: 'noindex' } : {}),
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

useJsonld({
    '@context': 'https://schema.org',
    '@graph': [
        {
            ...$lang.views.homepage.schemeOrg.types,
        },
        {
            ...$lang.views.homepage.schemeOrg.info,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.home-index-page {
    &__slider {
        @apply container mb-5;
    }
    &__content {
        @apply container mb-3 xl:flex xl:space-x-5;

        .main-content {
            @apply xl:order-1 xl:flex-grow;

            &__discount-list {
                h2 {
                    @apply mb-4 text-2xl font-semibold text-gray-800;
                }
            }

            .paragraph-with-image {
                @apply my-5 block justify-between pb-8 md:flex;
                &__paragraph {
                    @apply w-full text-sm text-gray-800 md:w-1/2;
                    h2 {
                        @apply flex items-center text-lg font-semibold text-site-primary;
                        img {
                            @apply h-4;
                        }
                    }
                    div {
                        @apply space-y-2;
                        span {
                            @apply text-lg text-site-primary;
                        }
                    }
                }
                &__image {
                    @apply mx-auto mt-2 h-64 self-center rounded-lg object-contain sm:mt-0;
                }
            }

            &__server-content {
                @apply mt-5 rounded-lg bg-white px-2 py-3 md:px-4 md:py-6 xl:px-8 xl:py-10;

                > div {
                    @apply prose-sm prose-gray prose-headings:font-semibold prose-p:text-sm prose-ol:list-decimal prose-ul:list-disc prose-li:marker:font-semibold prose-li:marker:text-site-primary;

                    @apply prose-headings:mt-0 prose-p:my-0 !important;
                }
            }

            &__subtitle {
                @apply mb-2 flex items-baseline space-x-2 pt-4;
                h2 {
                    @apply flex-none text-2xl font-semibold text-gray-800;
                }

                a {
                    @apply block border-l border-gray-300 pl-2 text-sm text-site-primary;
                }
            }
        }
        .sidebar-content {
            @apply mt-4 space-y-5 xl:order-2 xl:mt-0 xl:w-80 xl:flex-none xl:pt-0;
        }
    }
}
</style>
