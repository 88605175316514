<template>
    <div class="site-sidebar-banner">
        <p class="site-sidebar-banner__title">{{ $lang.components.sidebarBanners.publicity }}</p>
        <div class="site-sidebar-banner__banner-container">
            <a
                v-if="bannerTop"
                :href="bannerTop.url"
                target="_blank"
                rel="follow"
                class="site-sidebar-banner__link"
            >
                <picture class="site-sidebar-banner__picture">
                    <img
                        :src="bannerTop.image.url"
                        :alt="$lang.brand"
                        loading="lazy"
                        width="300"
                        height="200"
                    />
                </picture>
            </a>
            <div v-else class="site-sidebar-banner__skeleton"></div>
            <a
                v-if="bannerBottom"
                :href="bannerBottom.url"
                target="_blank"
                rel="follow"
                class="site-sidebar-banner__link"
            >
                <picture class="site-sidebar-banner__picture">
                    <img
                        :src="bannerBottom.image.url"
                        :alt="$lang.brand"
                        loading="lazy"
                        width="300"
                        height="200"
                    />
                </picture>
            </a>
            <div v-else class="site-sidebar-banner__skeleton"></div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'

const props = defineProps({
    bannerPos: {
        type: Array as PropType<number[]>,
        required: true,
    },
})

const RootStore = useRootStore()

const banners = computed(() => {
    return RootStore.layoutData?.data.sidebar.banners || []
})
const bannerTop = computed(() => {
    return banners.value.length && props.bannerPos[0]
        ? banners.value.find((banner) => banner.order === props.bannerPos[0])
        : undefined
})
const bannerBottom = computed(() => {
    return banners.value.length && props.bannerPos[1]
        ? banners.value.find((banner) => banner.order === props.bannerPos[1])
        : undefined
})

useHead({
    link: banners.value.map((item) => ({
        rel: 'preload',
        href: item.image.url,
        as: 'image',
        type: 'image/jpg',
        media: '(min-width: 1366px)',
    })),
})
</script>

<style lang="postcss" scoped>
.site-sidebar-banner {
    @apply rounded-xl bg-white p-2.5 pt-5;
    &__title {
        @apply mx-auto mb-3 max-w-max rounded-md bg-gray-100 px-3 py-1 text-sm;
    }
    &__link {
        @apply block flex-none;
        &:nth-child(2) {
            @apply mt-2.5;
        }
    }
    &__skeleton {
        @apply block h-[250px] w-[300px] animate-pulse rounded-xl bg-gray-100;
        &:nth-child(2) {
            @apply mt-2.5;
        }
    }
    &__picture {
        @apply mx-auto block h-[250px] w-[300px] overflow-hidden rounded-xl bg-white;
        img {
            @apply block h-full w-full object-contain;
        }
    }
    &__banner-container {
        @apply flex flex-wrap;
    }
}
</style>
