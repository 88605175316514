<template>
    <section class="sidebar-featured-coupons">
        <div class="sidebar-featured-coupons__heading">
            <h3 class="head">{{ $lang.components.sidebarFeaturedCoupons.featured_coupons }}</h3>
            <NuxtLink no-prefetch class="text" :to="`/${$lang.routes.offers}`" rel="follow">{{
                $lang.components.sidebarFeaturedCoupons.see_more
            }}</NuxtLink>
            <NuxtLink no-prefetch class="image" :to="`/${$lang.routes.offers}`" rel="follow">
                <img
                    :src="$assets.primary.fullArrowRight"
                    :alt="$lang.components.sidebarFeaturedCoupons.see_more"
                    :title="$lang.components.sidebarFeaturedCoupons.see_more"
                />
            </NuxtLink>
        </div>
        <ul class="sidebar-featured-coupons__list">
            <li v-for="(discount, index) of discounts" :key="index">
                <DCardSidebar :info="discount" />
            </li>
        </ul>
    </section>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'SidebarFeaturedCoupons',
    props: {
        discounts: { type: Array as PropType<Models.Discount[]> },
    },
})
</script>

<style lang="postcss" scoped>
.sidebar-featured-coupons {
    @apply rounded-xl border bg-white py-[0.20rem];
    &__heading {
        @apply flex flex-wrap justify-between gap-1 px-2 py-2;
        .head {
            @apply text-lg font-thin;
        }
        .text {
            @apply self-center text-sm font-medium text-site-primary hover:underline lg:hidden 2xl:block;
        }
        .image {
            @apply hidden h-4 w-4 self-center lg:block 2xl:hidden;
            img {
                @apply h-full w-full;
            }
        }
    }
    &__list {
        @apply w-full space-y-2;
        li {
            @apply px-2;
            &:not(:last-of-type) {
                @apply border-b pb-2;
            }
        }
    }
}
</style>
