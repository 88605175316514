<template>
    <NuxtLink
        no-prefetch
        v-if="!info.is_admin && !info.without_detail"
        :to="`/${$lang.routes.coupons}/${info.slug}`"
        class="card-main-sidebar-coupon"
        rel="follow"
    >
        <picture class="card-main-sidebar-coupon__image">
            <client-only>
                <img
                    :src="info.featured_image || ''"
                    :alt="info.title"
                    :title="info.title"
                    loading="lazy"
                    width="80"
                    height="80"
                    :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                />
            </client-only>
        </picture>
        <div class="right-wrapper">
            <h4 class="card-main-sidebar-coupon__shop">
                {{ info.title }}
            </h4>
            <div class="card-main-sidebar-coupon__info">
                <p
                    v-if="showNewPrice"
                    class="card-main-sidebar-coupon__new-price"
                    :class="{
                        'flex items-center space-x-1': isCoupon && info.discount_type === 'envío gratis',
                    }"
                >
                    <img
                        v-if="isCoupon && info.discount_type === 'envío gratis'"
                        :src="$assets.primary.shipment"
                        alt="icon"
                        class="h-5 w-5"
                    />
                    <span
                        :class="{
                            'text-sm font-normal text-site-primary':
                                isCoupon && info.discount_type === 'envío gratis',
                        }"
                        >{{ newPrice }}</span
                    >
                </p>
                <div class="status">{{ $lang.components.cardMainSidebarDiscount.see_coupon }}</div>
            </div>
        </div>
    </NuxtLink>
    <div v-else class="card-main-sidebar-coupon" rel="follow" @click="openCoupon">
        <picture class="card-main-sidebar-coupon__image is-admin">
            <img
                v-if="info.store && info.store.image"
                :src="info.store.image.url"
                :alt="info.title"
                :title="info.title"
                loading="lazy"
                width="80"
                height="80"
                :onerror="`this.onerror=null;this.src='${defaultImage}'`"
            />
        </picture>
        <div class="right-wrapper">
            <h4 class="card-main-sidebar-coupon__shop">
                {{ info.title }}
            </h4>
            <div class="card-main-sidebar-coupon__info">
                <p
                    v-if="showNewPrice"
                    class="card-main-sidebar-coupon__new-price"
                    :class="{
                        'flex items-center space-x-1': isCoupon && info.discount_type === 'envío gratis',
                    }"
                >
                    <img
                        v-if="isCoupon && info.discount_type === 'envío gratis'"
                        :src="$assets.primary.shipment"
                        alt="icon"
                        class="h-5 w-5"
                    />
                    <span
                        :class="{
                            'text-sm font-normal text-site-primary':
                                isCoupon && info.discount_type === 'envío gratis',
                        }"
                        >{{ newPrice }}</span
                    >
                </p>
                <div class="status">{{ $lang.components.cardMainSidebarDiscount.see_coupon }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import type { Models } from '~/types/models'
import { useVariantsDictionary } from '~/composables/VariantsDictionary'

const { defaultImages } = useVariantsDictionary()

const props = defineProps({
    info: {
        type: Object as PropType<Models.Discount>,
        required: true,
    },
    lazyImage: Boolean,
    expire: {
        type: Boolean,
        default: false,
    },
})

const { $lang, $openers } = useNuxtApp()

const defaultImage = defaultImages[props.info.type]

const isCoupon = props.info.type === 'coupon'
const isOffer = props.info.type === 'offer'

const openCoupon = () => {
    if (props.info.is_admin || props.info.without_detail) {
        $openers.openCoupon(props.info.slug, props.info.without_detail ? '' : props.info.store?.slug || '')
    } else {
        $openers.openCoupon(props.info.slug)
    }
}

const showNewPrice = computed(() => {
    return (
        (isOffer && !!props.info.new_price) ||
        (isCoupon &&
            (((props.info.discount_type === 'precio' || props.info.discount_type === 'porcentaje') &&
                props.info.discount_value !== '0') ||
                props.info.discount_type === 'envío gratis'))
    )
})

const newCouponPrice = computed(() => {
    return props.info.discount_type === 'envío gratis'
        ? $lang.components.cardMainGlobalFeatured.free_ship
        : props.info.discount_type === 'precio'
          ? $lang.symbol + props.info.discount_value + ' OFF'
          : props.info.discount_value === '100'
            ? $lang.components.cardMainGlobalFeatured.free
            : props.info.discount_value + '% OFF'
})

const newOfferPrice = computed(() => {
    return props.info.new_price === '0' ? 'GRATIS' : $lang.symbol + props.info.new_price
})

const newPrice = computed(() => {
    return (isOffer && newOfferPrice.value) || (isCoupon && newCouponPrice.value) || ''
})
</script>

<style lang="postcss" scoped>
.card-main-sidebar-coupon {
    @apply flex cursor-pointer items-center gap-x-2 py-1;
    .right-wrapper {
        @apply w-full space-y-1;
    }
    &__shop {
        @apply line-clamp-2 text-sm font-medium leading-4 text-black hover:underline lg:text-xs 2xl:text-sm;
    }
    &__image {
        @apply flex h-16 w-28 justify-start overflow-hidden rounded-lg border lg:h-12 lg:w-24 2xl:h-16 2xl:w-28;

        img {
            @apply h-full w-full object-cover;
        }

        &.is-admin {
            img {
                @apply h-full w-full object-contain p-1;
            }
        }
    }
    &__info {
        @apply flex items-center justify-end;
        .status {
            @apply flex w-fit items-center gap-1 rounded-md border-2 border-site-primary px-2 py-0.5 text-xs font-medium text-site-primary;
        }
    }
    &__new-price {
        @apply mr-auto text-base font-semibold text-site-primary;
    }
}
</style>
